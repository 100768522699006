import {initSwiperSlider} from "../../../../utils/init-swiper-slider";
import {templateProduct} from "../../products/components/template-announcement"
import {getProductsEndlessLoading} from "../../../../services/products-service";
import Cookie from "js-cookie";

/**
 * Additional records loading
 * status variable
 * @type {boolean}
 */
let status = true

document.addEventListener("DOMContentLoaded", e => {
    window.addEventListener('scroll', handleScroll)
})

const handleScroll = () => {
    const body = document.body,
        noResult = document.getElementById('no-result'),
        preload = document.getElementById('preload'),
        preporukSliders = document.getElementById('preporuk-sliders')

    let pageY = window.scrollY,
        height = body.offsetHeight

    pageY = pageY + 1800;

    if (pageY >= height && status && noResult.classList.contains('hide')) {
        status = false;

        const cols = preporukSliders.querySelectorAll('.col-6').length;

        preload.style.display = 'flex';

        getProductsEndlessLoading({
            skip: cols,
            take: 24,
            countryId: Cookie.get('hitno_country')
        }).then(e => {
            insertHtmlTemplateAds(e.data.data)
            status = true
            preload.style.display = 'none'
        }).catch(() => {
            status = true
            preload.style.display = 'none'
        });
    }
}

/**
 *
 * @param data
 * @returns {boolean}
 */
const insertHtmlTemplateAds = async (data) => {
    let dom = ``

    /**
     * Change the status of the variable
     * letting you know that everything
     * has loaded
     * @type {boolean}
     */
    status = true

    /**
     * Hide the preloader after
     * loading
     * @type {string}
     */
    document.getElementById('preload').style.display = 'none'

    /**
     * Displays a block that there are
     * no more records if no records have arrived
     */
    if (data.length === 0) {
        document.getElementById('no-result').classList.remove('hide')
    }

    /**
     * Starts the ad loading cycle
     */
    for (let i = 0; data.length > i; i++) {
        dom += templateProduct(data[i], 'col-lg-3 col-md-3 col-sm-6 col-6')
    }

    /**
     *
     */
    document.getElementById('preporuk-sliders').insertAdjacentHTML('beforeend', dom)

    /**
     * Triggers after 500ms the
     * activation of the slider on ads
     */
    initSwiperSlider()
}
